import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';
import { Card } from '../../UI/Card';
import { PlanCard } from './PlanCard';
import { getSafe } from '../../utils/getSafe';
import Button from '../../UI/Button';
import './Plans.scss';

export default function Plans({ fullData, ref }) {
    const renderMoreButton = fullData => {
        return (
            <div className="button-div">
                <Button
                    to="/planes"
                    text="Ver todos los planes"
                />
            </div>
        );
    };

    return (
        <StaticQuery
            query={graphql`
                query {
                    allContentfulPlanes(
                        filter: { mostrarEnPaginaInicial: { eq: true } }
                        sort: { fields: [posicion, createdAt], order: ASC }
                        limit: 3
                    ) {
                        edges {
                            node {
                                posicion
                                precio
                                caracteristicas
                                titulo {
                                    titulo
                                }
                            }
                        }
                    }
                    contentfulInicioPagina {
                        tituloSeccionPlanes
                        descripcionSeccionPlanes {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                }
            `}
            render={data => {
                const planesPresenciales = getSafe(() => data.allContentfulPlanes, []);
                const titulo = getSafe(() => data.contentfulInicioPagina.tituloSeccionPlanes, 'Planes');
                const descripcion = getSafe(
                    () => data.contentfulInicioPagina.descripcionSeccionPlanes.childMarkdownRemark.html,
                    ''
                );
                return (
                    <section className={`cfp-plans ${fullData ? 'gray' : ''}`} ref={ref}>
                        <div className="child cfp-container">
                            <Card titulo={titulo} descripcion={descripcion} fullData={fullData} />
                            <div
                                className={`content-grid plan-grid ${fullData ? 'full' : ''}`}
                                style={{ alignItems: 'flex-start' }}
                            >
                                {planesPresenciales &&
                                    planesPresenciales.edges.map((plan, i) => {
                                        return (
                                            <PlanCard
                                                {...plan.node}
                                                tipo="Presencial"
                                                key={i}
                                                i={i}
                                                fullData={fullData}
                                            />
                                        );
                                    })}
                            </div>
                            {renderMoreButton()}
                        </div>
                        {!fullData && (
                            <div className="rectangle-top-right">
                                <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
                                    <img src="/rectangle.svg" alt="Rectangle" className="rectangle-img" />
                                </ScrollAnimation>
                            </div>
                        )}
                    </section>
                );
            }}
        />
    );
}
