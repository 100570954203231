import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import "./SubTitle.scss";

const SubTitle = ({ children, delay = 0, atTop = false }) => {
    return (
        <ScrollAnimation 
            animateIn="fadeIn" 
            animateOnce={true} 
            className={`sub text ${atTop ? 'top': '' }`}
            delay={delay} >
            <p dangerouslySetInnerHTML={{__html: children}}/>
        </ScrollAnimation>
    );
};

export default SubTitle;
