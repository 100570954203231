import React from 'react';
import Layout from '../components/Layout/Layout';
import Nutritionists from '../components/Nutritionist/Nutritionist';
import Plans from '../components/Plans/PlansIndex';
// import Patients from '../components/Patients/PatientsAux';

const HomePage = props => (
    <Layout title="Inicio" {...props}>
        <Nutritionists />
        <Plans />
        {/* <Patients /> */}
    </Layout>
);

export default HomePage;
