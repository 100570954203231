import React from 'react';
import Title from './Title';
import SubTitle from './SubTitle';

export const Card = ({titulo, descripcion, fullData = false }) => {
    return (
        <>
            {!fullData && <Title text={titulo} delay={200} />}
            <SubTitle delay={400} atTop={fullData ? true: false} >{descripcion}</SubTitle>
        </>
    );
}
