import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import './PlanCard.scss';
import { getSafe } from '../../utils/getSafe';

export const PlanCard = ({
    titulo,
    precio,
    precioOnline,
    precioEliana = '',
    precioElianaOnline,
    elianaPrice = false,
    caracteristicas,
    subtitulo,
    fullData,
    i,
    tipo,
}) => {
          /* Ignorar los que no tengan precio especial para Eliana */
          if (elianaPrice && !precioEliana) return null;

          /* Colors for the plans top and checkmarks */
          const colors = ['#9d9fa2', '#87b136', '#9b5fa3', '#ffa638', '#319e99', '#8faede', ''];

          /* Get the name of the plan */
          const name = (titulo, tipo) => (
              <div className="type" style={{ borderColor: colors[i % (colors.length - 1)] }}>
                  {titulo}
                  {tipo !== undefined ? <p>- {tipo} -</p> : ''}
              </div>
          );

          /* Get the price of the plan */
          const getPrice = (precio, precioEliana) => {
              return precioEliana !== null ? precioEliana : precio;
          };

          /* Get the price div of the plan */
          const price = (precio, precioEliana, elianaPrice, title) => {
              if (elianaPrice && !precioEliana) return null;
              if (!precio && !elianaPrice) return null;
              else {
                  return (
                      <div className="price-detail" style={{ color: colors[i % (colors.length - 1)] }}>
                          {elianaPrice ? getPrice(precio, precioEliana) : precio} USD
                          <div className="detail" dangerouslySetInnerHTML={{ __html: title }} />
                      </div>
                  );
              }
          };

          /* Get the description of the plan */
          const getDescription = subtitulo => (
              <div className="subtitulo">{subtitulo ? getSafe(() => subtitulo.subtitulo) : ''}</div>
          );

          return (
              <div className={`plan ${fullData ? '' : 'show-best'}`}>
                  {name(titulo.titulo, tipo)}
                  {getDescription(subtitulo)}
                  <div className="price">
                      
                      {price(precio, precioEliana, elianaPrice, '- Presencial / Online -')}
                      {/* {(precioElianaOnline || precioOnline) &&
                          price(precioOnline, precioElianaOnline, elianaPrice, '- Online -')} */}
                  </div>
                  <div className="plan-content">
                      <div className="list">
                          {caracteristicas &&
                              caracteristicas.map((item, i) => {
                                  return (
                                      <div className="item text" key={i}>
                                          <CheckIcon /> <span dangerouslySetInnerHTML={{ __html: item }} />
                                      </div>
                                  );
                              })}
                      </div>
                      <div className="button-div">
                          <a
                              className="cfp-button complementary"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://api.whatsapp.com/send?phone=50764896899&text=Hola, estoy interesad@ en el plan "${titulo.titulo}". ¿Me pueden dar información sobre sus medios de pago?. Gracias.`}
                          >
                              Me interesa
                          </a>
                      </div>
                  </div>
              </div>
          );
      };
