import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { StaticQuery, graphql } from 'gatsby';
import { Card } from '../../UI/Card';
import { getSafe } from '../../utils/getSafe';
import { NutriCard } from './NutriCard';

export default function Nutricionist() {
    return (
        <StaticQuery
            query={graphql`
                query {
                    contentfulInicioPagina {
                        descripcionSeccionNutricionista {
                            descripcionSeccionNutricionista
                        }
                        tituloSeccionNutricionista
                    }
                    allContentfulNutricionistas(sort: { fields: orden, order: ASC }) {
                        edges {
                            node {
                                instagram
                                especialidad
                                descripcion {
                                    descripcion
                                }
                                nombre
                                usuarioCliniweb
                                orden
                                foto {
                                    sizes(maxWidth: 600) {
                                        ...GatsbyContentfulSizes
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={data => {
                const nutricionistas = getSafe(() => data.allContentfulNutricionistas.edges, []);
                const nutriSection = getSafe(() => data.contentfulInicioPagina, {});
                const titulo = getSafe(() => nutriSection.tituloSeccionNutricionista);
                const descripcion = getSafe(() => nutriSection.descripcionSeccionNutricionista.descripcionSeccionNutricionista);
                return (
                    <section className="cfp-nutritionist">
                        <div className="child cfp-container">
                            <Card titulo={titulo} descripcion={descripcion} />
                            <div className="content-grid smaller-grid">
                                {nutricionistas &&
                                    nutricionistas.map((nutri, i) => {
                                        return <NutriCard {...nutri.node} key={i} />;
                                    })}
                            </div>
                        </div>
                        <div className="rectangle-left">
                            <ScrollAnimation animateIn="slideInLeft" animateOnce={true}>
                                <img src="/rectangle.svg" alt="Rectangle" className="rectangle-img" />
                            </ScrollAnimation>
                        </div>
                    </section>
                );
            }}
        />
    );
}
