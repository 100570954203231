import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
import Button from '../../UI/Button';
import './NutriCard.scss';

export const NutriCard = ({ foto, nombre, especialidad, descripcion, instagram, orden }) => {
    const getImage = foto => foto && <Img className="post-image" sizes={foto.sizes} alt="Imagen" />;
    return (
        <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="nutri">
            {foto && <div className="photo">{getImage(foto)}</div>}
            {instagram && (
                <div className="ig-icon">
                    <a href={instagram} rel="noreferrer noopener" target="_blank">
                        <img src={require('../../assets/images/icons/ig.png')} alt="IG" />
                    </a>
                </div>
            )}
            <div className="nutri-content">
                {nombre && <div className="cfp-content-title">{nombre}</div>}
                {especialidad && <div className="speciality">{especialidad}</div>}
                {descripcion && (
                    <div
                        className="cfp-content-description text"
                        dangerouslySetInnerHTML={{ __html: descripcion.descripcion.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                    />
                )}
                <div className="button-end">
                    <Button to={`/agendar?nutr${orden}`} text="Agendar cita" />
                </div>
            </div>
        </ScrollAnimation>
    );
};
